// This reducer stores all data needed for Opportunities.js component
import { createSlice } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import axios from "axios";
require("dotenv").config();

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  opportunities: {},
  lastUpdated: null,
};

const slice = createSlice({
  name: "opportunities",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getOpportunitiesSuccess(state, action) {
      state.opportunities = action.payload;
      state.lastUpdated = new Date();

      state.isLoading = false;
      state.error = false;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getOpportunities() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    await Auth.currentSession()
      .then(async (session) => {
        let token = session.getIdToken();

        await axios
          .get(process.env.REACT_APP_API_URL + "/v1/opportunities", {
            headers: {
              "Authorization": token.jwtToken,
              "X-API-KEY": process.env.REACT_APP_API_KEY,
            },
          })
          .then((response) => {
            dispatch(slice.actions.getOpportunitiesSuccess(response.data.response));
          })
          .catch(async (error) => {
            dispatch(slice.actions.hasError(error));

            if (
              error.response.status === 403 ||
              error.response.status === 401
            ) {
              await Auth.signOut();
            }
          });
      })
      .catch((error) => {
        dispatch(slice.actions.hasError(error));
      });
  };
}
