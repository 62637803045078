// Modules
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

// Reducers
import dashboard from './reducers/dashboard.reducer';
import customers from './reducers/customers.reducer';
import competitors from './reducers/competitors.reducer';
import opportunities from './reducers/opportunities.reducer';
import user from './reducers/user.reducer';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  version: 1,
};

// rootReducer is the primary reducer for our entire project
// It bundles up all of the other reducers so our project can use them.
// This is imported in index.js as rootReducer
const rootReducer = combineReducers({
  // Add all the reducers you imported here so they can be exported
  dashboard,
  customers,
  competitors,
  opportunities,
  user,          // contains info about logged-in user
});

export { rootPersistConfig, rootReducer };
