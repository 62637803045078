// Modules
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

// Components
import { PATH_APP } from './paths';
import { DefaultLayout } from '../layouts';

// ----------------------------------------------------------------------

// App Routes

const AppRoutes = {
  path: PATH_APP.root,
  layout: DefaultLayout,
  routes: [
    // Dashboard
    {
      exact: true,
      path: PATH_APP.dashboard,
      component: lazy(() => import('../views/app/DashboardView'))
    },
    // Customers
    {
      exact: true,
      path: PATH_APP.customers,
      component: lazy(() => import('../views/app/CustomersView'))
    },
    // Competitors
    {
      exact: true,
      path: PATH_APP.competitors,
      component: lazy(() => import('../views/app/CompetitorsView'))
    },
    // Opportunities
    {
      exact: true,
      path: PATH_APP.opportunities,
      component: lazy(() => import('../views/app/OpportunitiesView'))
    },
    // Details
    {
      exact: true,
      path: PATH_APP.details,
      component: lazy(() => import('../views/app/DetailsView'))
    },
    // Success
    {
      exact: true,
      path: PATH_APP.success,
      component: lazy(() => import('../views/app/SuccessView'))
    },
    // Root redirect
    {
      exact: true,
      path: PATH_APP.root,
      component: () => <Redirect to={PATH_APP.root} />
    },
    // Errors
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default AppRoutes;
