// This reducer stores information about the logged-in user (a financial institution)
import { createSlice } from "@reduxjs/toolkit";
import AWS from "aws-sdk";
require("dotenv").config();

// ----------------------------------------------------------------------

var cognitoClient = new AWS.CognitoIdentityServiceProvider({
  region: process.env.REACT_APP_AMPLIFY_REGION,
  accessKeyId: "AKIA4U3OMEKSJYZKPMGK",
  secretAccessKey: "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",
});

const initialState = {
  isLoading: false,
  error: false,
  user: null,
  team: [],
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    getTeamSuccess(state, action) {
      console.log(action.paylod);
      state.team = action.payload;
    },
    deleteTeamMemberSuccess(state, action) {
      state.team = state.team.filter((value) => {
        return value.Attributes.email !== action.payload;
      });
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setUser } = slice.actions;

// ----------------------------------------------------------------------

export function getTeam() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const command = cognitoClient
        .listUsers({
          UserPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
        })
        .promise();

      command
        .then((response) => {
          response.Users.map((User) => {
            let stuff = {};
            User.Attributes.map((attr) => {
              if (attr.Name.includes("custom:")) {
                stuff[attr.Name.slice(7)] = attr.Value;
              } else stuff[attr.Name] = attr.Value;
            });
            User.Attributes = stuff;
          });

          dispatch(slice.actions.getTeamSuccess(response.Users));
        })
        .catch((err) => {
          console.log(err, err.stack);
          dispatch(slice.actions.hasError(err));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteTeamMember(email) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const command = cognitoClient
        .adminDeleteUser({
          UserPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
          Username: email,
        })
        .promise();

      command
        .then((response) => {
          console.log(response);
          dispatch(slice.actions.deleteTeamMemberSuccess(email));
        })
        .catch((err) => {
          console.log(err, err.stack);
          dispatch(slice.actions.hasError(err));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
