// Modules
import React from "react";

// Components
import MainNavbar from "../components/layout/MainNavbar";
import MainFooter from "../components/layout/MainFooter";

const DefaultLayout = ({ children }) => (
  <body className="footer-offset">
    <header
      id="header"
      className="navbar navbar-expand-lg navbar-fixed navbar-height navbar-flush navbar-container navbar-bordered navbar-shadow"
    >
      <MainNavbar />
    </header>

    <main id="content" role="main" className="main pointer-event">
      {children}
    </main>

    <MainFooter />
  </body>
);

export default DefaultLayout;
