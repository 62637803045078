// Modules
import React from "react";
import PropTypes from "prop-types"

const PageHeader = ({ title }) => {
    return (
        <div className="page-header">
            <div className="row align-items-center">
                <div className="col-sm mb-2 mb-sm-0">
                    <h1 className="page-header-title">{title}</h1>
                </div>
            </div>
        </div>
    )
}

PageHeader.propTypes = {
    title: PropTypes.string.isRequired,
};

export default PageHeader;
