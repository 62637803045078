// This reducer stores all data needed for Dashboard.js component
import { createSlice } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import axios from "axios";
require("dotenv").config();

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  meta: {},
  institutions: {},
  userActivity: {},
  lastUpdated: null,
};

const slice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getDashboardSuccess(state, action) {
      let { meta, institutions, userActivity } = action.payload;

      state.meta = meta;
      state.institutions = institutions;
      state.userActivity = userActivity;

      let history = JSON.parse(localStorage.getItem("history"));

      if (history === null) {
        localStorage.setItem(
          "history",
          JSON.stringify({
            kpi: {
              total_users: [userActivity.totalUsers],
              engagement_rate: [userActivity.engagementRate],
              conversion_rate: [userActivity.conversionRate],
              revenue_generated: [userActivity.revenueGenerated],
            },
            dashboard: {
              checking: meta.external.depository.checking.totalValue,
              cd: meta.external.depository.cd.totalValue,
              savings: meta.external.depository.savings.totalValue,
              // money_market: meta.external.depository.money_market.totalValue,
              prepaid: meta.external.depository.prepaid.totalValue,
              // credit_card: meta.external.depository.credit_card.totalValue,
              paypal: meta.external.credit.paypal.totalValue,
              // student: meta.external.depository.student.totalValue || 0,
              // mortgage: meta.external.depository.mortgage.totalValue || 0,
            },
          })
        );
      } else {
        localStorage.setItem(
          "history",
          JSON.stringify({
            kpi: {
              total_users: [
                ...history.kpi.total_users,
                userActivity.totalUsers,
              ],
              engagement_rate: [
                ...history.kpi.engagement_rate,
                userActivity.engagementRate,
              ],
              conversion_rate: [
                ...history.kpi.conversion_rate,
                userActivity.conversionRate,
              ],
              revenue_generated: [
                ...history.kpi.revenue_generated,
                userActivity.revenueGenerated,
              ],
            },
            dashboard: {
              checking: meta.external.depository.checking.totalValue,
              cd: meta.external.depository.cd.totalValue,
              savings: meta.external.depository.savings.totalValue,
              // money_market: meta.external.depository.money_market.totalValue,
              prepaid: meta.external.depository.prepaid.totalValue,
              // credit_card: meta.external.depository.credit_card.totalValue,
              paypal: meta.external.credit.paypal.totalValue,
              // student: meta.external.depository.student.totalValue || 0,
              // mortgage: meta.external.depository.mortgage.totalValue || 0,
            },
          })
        );
      }

      state.lastUpdated = new Date();

      state.isLoading = false;
      state.error = false;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getDashboard() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    await Auth.currentSession()
      .then(async (session) => {
        let token = session.getIdToken();

        await axios
          .get(process.env.REACT_APP_API_URL + "/v1/dashboard", {
            headers: {
              "Authorization": token.jwtToken,
              "X-API-KEY": process.env.REACT_APP_API_KEY,
            },
          })
          .then((response) => {
            dispatch(slice.actions.getDashboardSuccess(response.data.response));
          })
          .catch(async (error) => {
            dispatch(slice.actions.hasError(error));

            if (
              error.response.status === 403 ||
              error.response.status === 401
            ) {
              await Auth.signOut();
            }
          });
      })
      .catch((error) => {
        dispatch(slice.actions.hasError(error));
      });
  };
}
