// Modules
import React from "react";
import { FiHeart } from "react-icons/fi";

const MainFooter = () => (
  <div className="footer">
    <div className="row justify-content-between align-items-center">
      <div className="col">
        <p className="font-size-sm mb-0">
          &copy;{" "}
          <span className="d-none d-sm-inline-block">2021 Finotta Inc.</span>
        </p>
      </div>
      <div className="col-auto">
        <div className="d-flex justify-content-end">
          <ul className="list-inline list-separator">
            <li className="list-inline-item">
              <a className="list-separator-link">
                Made with&nbsp;
                <FiHeart />
                &nbsp;from MCI to ORD
              </a>
            </li>

            <li className="list-inline-item">
              <a className="list-separator-link" href="#">
                FAQ
              </a>
            </li>

            <li className="list-inline-item">
              <a className="list-separator-link" href="#">
                License
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default MainFooter;
