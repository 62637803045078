// Modules
import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Route } from "react-router-dom";

// Routes
import AppRoutes from "./AppRoutes";
import UserRoutes from "./UserRoutes";

// Components
import PageHeader from "../components/common/PageHeader";

// ----------------------------------------------------------------------

export function renderRoutes(routes = []) {
  return (
    <Suspense fallback={<PageHeader title={"Loading"} />}>
      <Switch>
        {routes.map((route, index) => {
          const Layout = route.layout || Fragment;
          const Component = route.component;
          // const Guard = route.guard || Fragment;
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                // <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                // </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}

const routes = [
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("../views/other/Error404")),
  },
  {
    exact: true,
    path: "/500",
    component: lazy(() => import("../views/other/Error500")),
  },
  // App Routes
  AppRoutes,

  // User Routes
  UserRoutes,
];

export default routes;
