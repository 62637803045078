// Modules
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import Amplify from "aws-amplify";
import { Provider } from "react-redux";
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/lib/integration/react';
import GoogleAnalytics from "react-ga";

// Components
import App from "./App";
import { amplifyConfig } from "./config";
import PageHeader from './components/common/PageHeader';

// Assets
import './assets/css/theme.css';

// ----------------------------------------------------------------------

GoogleAnalytics.initialize(process.env.REACT_APP_GAID);
Amplify.configure(amplifyConfig);

// ----------------------------------------------------------------------

ReactDOM.render(
  <Suspense fallback={<PageHeader title={"Loading"} />}>
    <Provider store={store}>
      <PersistGate loading={<PageHeader title={"Loading"} />} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Suspense>,
  document.getElementById("root")
);
