export const amplifyConfig = {
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_COGNITO_REGION,
    identityPoolRegion: process.env.REACT_APP_IDENTITY_POOL_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: true,
    cookieStorage: {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      path: process.env.REACT_APP_COOKIE_PATH,
      expires: Number(process.env.REACT_APP_COOKIE_EXPIRES),
      sameSite: process.env.REACT_APP_COOKIE_SAME_SITE,
      secure: process.env.REACT_APP_NODE_ENV === "prod" ? true : false,
    },
    authenticationFlowType: process.env.REACT_APP_AUTH_TYPE,
  },
}

// export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;
