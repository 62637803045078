// Modules
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { PATH_APP, PATH_USER } from "../../routes/paths";
import { Modal, Backdrop, Fade, Popover, Tooltip } from "@material-ui/core";
import {
  FiHome,
  FiUsers,
  FiAirplay,
  FiAward,
  FiSearch,
  FiBell,
  FiMail,
  FiHelpCircle,
  FiAlignJustify,
} from "react-icons/fi";
import { AmplifySignOut } from "@aws-amplify/ui-react";

// Components
import SupportModal from "./SupportModal";

// Assets
import logo from "../../images/logos/finotta-logo-blue.png";

const MainNavbar = () => {
  // Rexux state
  const { user } = useSelector((store) => store.user);

  // Local state
  const [supportOpen, setSupportOpen] = useState(false);
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  // Functions
  const handleSupportOpen = () => {
    setSupportOpen(true);
  };
  const handleSupportClose = () => {
    setSupportOpen(false);
  };

  const handleUserClick = (e) => {
    setUserAnchorEl(e.currentTarget);
  };
  const handleUserClose = () => {
    setUserAnchorEl(null);
  };

  const handleMenuClick = (e) => {
    setMenuAnchorEl(e.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const userOpen = Boolean(userAnchorEl);
  const userAnchorId = userOpen ? "simple-popover" : undefined;

  const menuOpen = Boolean(menuAnchorEl);
  const menuAnchorId = menuOpen ? "simple-popover" : undefined;

  return (
    <div className="js-mega-menu navbar-nav-wrap hs-menu-initialized hs-menu-horizontal">
      {/* Branding */}
      <div className="navbar-brand-wrapper">
        {/* Logo */}
        <a
          className="navbar-brand"
          href={PATH_APP.dashboard}
          aria-label="Finotta"
        >
          <img
            className="navbar-brand-logo"
            src={logo}
          />
        </a>
      </div>

      {/* Right Side */}
      <div className="navbar-nav-wrap-content-right">
        <ul className="navbar-nav align-items-center flex-row">
          {/* Search */}
          <Tooltip title="Search is coming soon" arrow>
            <li className="nav-item d-none d-sm-inline-block">
              <button className="btn btn-icon btn-ghost-secondary rounded-circle">
                <FiSearch />
              </button>
            </li>
          </Tooltip>

          {/* Notifications */}
          <Tooltip title="Notifications are coming soon" arrow>
            <li className="nav-item d-none d-sm-inline-block">
              <button className="btn btn-icon btn-ghost-secondary rounded-circle">
                <FiBell />
                <span className="btn-status btn-sm-status btn-status-danger"></span>
              </button>
            </li>
          </Tooltip>

          {/* Messages */}
          <Tooltip title="Messages are coming soon" arrow>
            <li className="nav-item d-none d-sm-inline-block">
              <button className="btn btn-icon btn-ghost-secondary rounded-circle">
                <FiMail />
              </button>
            </li>
          </Tooltip>

          {/* Help */}
          <li className="nav-item d-none d-sm-inline-block">
            <div>
              <button
                className="btn btn-soft-primary btn-sm"
                onClick={handleSupportOpen}
              >
                <FiHelpCircle /> Need Help?
              </button>
              <Modal
                key={1}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                open={supportOpen}
                onClose={handleSupportClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={supportOpen}>
                  <SupportModal clientId={1} onClose={handleSupportClose} />
                </Fade>
              </Modal>
            </div>
          </li>

          {/* User */}
          <li className="nav-item">
            <a
              className="navbar-dropdown-account-wrapper"
              onClick={handleUserClick}
            >
              <div className="avatar avatar-sm avatar-circle">
                <img
                  className="avatar-img"
                  src={process.env.REACT_APP_S3_URL + "/finotta-logo.svg"}
                  alt="Avatar logo"
                />
                <span className="avatar-status avatar-sm-status avatar-status-success"></span>
              </div>
            </a>

            <Popover
              id={userAnchorId}
              open={userOpen}
              anchorEl={userAnchorEl}
              onClose={handleUserClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div className="" style={{ width: "16rem" }}>
                <div className="dropdown-item">
                  <div className="media align-items-center">
                    <div className="avatar avatar-sm avatar-circle mr-2">
                      <img
                        className="avatar-img"
                        src={process.env.REACT_APP_S3_URL + "/finotta-logo.svg"}
                        alt="Image Description"
                        href={PATH_USER.profile}
                      />
                    </div>
                    <div className="media-body">
                      <span className="card-title h5">
                        {user.attributes["custom:firstName"] +
                          " " +
                          user.attributes["custom:lastName"]}
                      </span>
                      <span className="card-text">{user.username}</span>
                    </div>
                  </div>
                </div>

                <div className="dropdown-divider"></div>

                <a className="dropdown-item" href={PATH_USER.profile}>
                  <span className="text-truncate pr-2" title="Account">
                    Account
                  </span>
                </a>

                <a className="dropdown-item" href={PATH_USER.contact}>
                  <span className="text-truncate pr-2" title="Contact us">
                    Contact us
                  </span>
                </a>

                {/* <div className="dropdown-divider"></div> */}

                <AmplifySignOut
                  buttonText="Log out"
                  handleAuthStateChange={() => {
                    location.reload();
                  }}
                />
              </div>
            </Popover>
          </li>

          {/* Hamburger */}
          <li className="nav-item">
            <button
              type="button"
              className="navbar-toggler btn btn-ghost-secondary rounded-circle collapsed"
              onClick={handleMenuClick}
            >
              <FiAlignJustify />
            </button>
            <Popover
              id={menuAnchorId}
              open={menuOpen}
              anchorEl={menuAnchorEl}
              onClose={handleMenuClose}
              anchorReference="anchorPosition"
              anchorPosition={{ top: 150, left: 50 }}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
            >
              <div className="navbar-body" style={{ width: "30rem" }}>
                <ul className="navbar-nav flex-grow-1">
                  <li className="hs-has-sub-menu">
                    <a
                      className={
                        window.location.href.includes("dashboard")
                          ? "navbar-nav-link nav-link active"
                          : "navbar-nav-link nav-link"
                      }
                      href={PATH_APP.dashboard}
                    >
                      <FiHome /> Home
                    </a>
                  </li>

                  <li className="hs-has-sub-menu">
                    <a
                      className={
                        window.location.href.includes("customers") ||
                        window.location.href.includes("details/user")
                          ? "navbar-nav-link nav-link active"
                          : "navbar-nav-link nav-link"
                      }
                      href={PATH_APP.customers}
                    >
                      <FiUsers /> Customers
                    </a>
                  </li>

                  <li className="hs-has-sub-menu">
                    <a
                      className={
                        window.location.href.includes("competitors") ||
                        window.location.href.includes("details/institution")
                          ? "navbar-nav-link nav-link active"
                          : "navbar-nav-link nav-link"
                      }
                      href={PATH_APP.competitors}
                    >
                      <FiAirplay /> Competitors
                    </a>
                  </li>

                  <li>
                    <a
                      className={
                        window.location.href.includes("opportunities")
                          ? "navbar-nav-link nav-link active"
                          : "navbar-nav-link nav-link"
                      }
                      href={PATH_APP.opportunities}
                    >
                      <FiAward /> Opportunities
                    </a>
                  </li>
                </ul>
              </div>
            </Popover>
          </li>
        </ul>
      </div>

      {/* Pages */}
      <div
        className="navbar-nav-wrap-content-left collapse navbar-collapse"
        id="navbarNavMenu"
      >
        <div className="navbar-body">
          <ul className="navbar-nav flex-grow-1">
            <li className="hs-has-sub-menu">
              <a
                className={
                  window.location.href.includes("dashboard")
                    ? "navbar-nav-link nav-link active"
                    : "navbar-nav-link nav-link"
                }
                href={PATH_APP.dashboard}
              >
                <FiHome /> Home
              </a>
            </li>

            <li className="hs-has-sub-menu">
              <a
                className={
                  window.location.href.includes("customers") ||
                  window.location.href.includes("details/user")
                    ? "navbar-nav-link nav-link active"
                    : "navbar-nav-link nav-link"
                }
                href={PATH_APP.customers}
              >
                <FiUsers /> Customers
              </a>
            </li>

            <li className="hs-has-sub-menu">
              <a
                className={
                  window.location.href.includes("competitors") ||
                  window.location.href.includes("details/institution")
                    ? "navbar-nav-link nav-link active"
                    : "navbar-nav-link nav-link"
                }
                href={PATH_APP.competitors}
              >
                <FiAirplay /> Competitors
              </a>
            </li>

            <li>
              <a
                className={
                  window.location.href.includes("opportunities")
                    ? "navbar-nav-link nav-link active"
                    : "navbar-nav-link nav-link"
                }
                href={PATH_APP.opportunities}
              >
                <FiAward /> Opportunities
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MainNavbar;
