// Modules
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

// Components
import { PATH_USER } from './paths';
import { DefaultLayout } from '../layouts';

// ----------------------------------------------------------------------

// User Routes

const UserRoutes = {
  path: '/user',
  layout: DefaultLayout,
  routes: [
    // Profile
    {
      exact: true,
      path: PATH_USER.profile,
      component: lazy(() => import('../views/user/ProfileView'))
    },
    // Contact
    {
      exact: true,
      path: PATH_USER.contact,
      component: lazy(() => import('../views/user/ContactView'))
    },
    // Root redirect
    {
      exact: true,
      path: PATH_USER.root,
      component: () => <Redirect to={PATH_USER.profile} />
    },

    // Errors
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default UserRoutes;
