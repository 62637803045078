// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  app: "/app",
  user: "/user",
};

export const PATH_APP = {
  root: ROOTS.app,
  dashboard: path(ROOTS.app, "/dashboard"),
  customers: path(ROOTS.app, "/customers"),
  competitors: path(ROOTS.app, "/competitors"),
  opportunities: path(ROOTS.app, "/opportunities"),
  details: path(ROOTS.app, "/details/:type/:id"),
  success: path(ROOTS.app, "/success"),
};

export const PATH_USER = {
  root: ROOTS.user,
  profile: path(ROOTS.user, "/profile"),
  contact: path(ROOTS.user, "/contact"),
};
