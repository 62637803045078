// Modules
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { PATH_APP } from "../../routes/paths";
import { Auth } from "aws-amplify";
import axios from "axios";
require("dotenv").config();

// Components
import SupportForm from "./SupportForm";

const SupportModal = ({ clientId, onClose }) => {
  const history = useHistory();

  // Form schema
  const SupportTicketSchema = Yup.object().shape({
    type: Yup.string().required("Support type is required"),
    message: Yup.string().required("Support details are required"),
  });

  // Form Formik
  const formik = useFormik({
    initialValues: {
      type: "",
      message: "",
    },
    validationSchema: SupportTicketSchema,
    onSubmit: async (values, { setSubmitting }) => {
      await Auth.currentSession().then(async (session) => {
        let token = session.getIdToken();

        await axios
          .post(
            process.env.REACT_APP_API_URL + "/v1/support",
            {
              type: values.type,
              message: values.message,
            },
            {
              headers: {
                "Authorization": token.jwtToken,
                "X-API-KEY": process.env.REACT_APP_API_KEY,
              },
            }
          )
          .then((response) => {
            onClose();
            history.push(PATH_APP.success);
          })
          .catch(async (error) => {
            if (
              error.response.status === 403 ||
              error.response.status === 401
            ) {
              await Auth.signOut();
            }

            onClose();
            history.push("/500");
          });
      });
    },
  });

  return (
    <div className="modal-dialog modal-dialog-centered" role="document">
      <SupportForm formik={formik} onClose={onClose} />
    </div>
  );
};

export default SupportModal;
