// Modules
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, FormikProvider } from "formik";
import {
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import { FiX } from "react-icons/fi";

// Utils
import { passwordError, emailError } from "../../utils/firebaseShowError";

const SupportForm = ({ formik, onClose }) => {
  const [value, setValue] = useState(null);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form
        className="modal-content js-validate"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
      >
        <div className="modal-header">
          <h4 id="inviteUserModalTitle" className="modal-title">
            What can we help you with?
          </h4>

          <button
            type="button"
            className="btn btn-icon btn-sm btn-ghost-secondary"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onClose}
          >
            <FiX />
          </button>
        </div>

        <div className="modal-body">
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={value}
            onChange={handleChange}
            {...getFieldProps("type")}
          >
            <FormControlLabel
              value="bug"
              control={<Radio />}
              label="Report a bug"
            />
            <FormControlLabel
              value="feature"
              control={<Radio />}
              label="Request a feature"
            />
            <FormControlLabel
              value="feedback"
              control={<Radio />}
              label="Leave feedback"
            />
            <FormControlLabel
              value="learn"
              control={<Radio />}
              label="Learn how to use Finotta"
            />
            <FormControlLabel value="other" control={<Radio />} label="Other" />
          </RadioGroup>

          <div className="form-group js-form-message">
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="Tell us what you're thinking..."
              {...getFieldProps("message")}
              multiline
              rows={4}
              defaultValue=""
              variant="outlined"
              error={
                Boolean(touched.email && errors.email) ||
                emailError(errors.afterSubmit).error
              }
              helperText={
                (touched.email && errors.email) ||
                emailError(errors.afterSubmit).helperText
              }
            />
          </div>
        </div>

        <div className="modal-footer justify-content-start">
          <div className="row align-items-center flex-grow-1 mx-n2">
            <div className="col-sm-9 mb-2 mb-sm-0">
              <p className="modal-footer-text">
                Thank you! We'll send help your way immediately.
              </p>
            </div>

            <div className="col-sm-3 text-sm-right">
              <button
                type="submit"
                className="btn btn-sm btn-block btn-primary"
                disabled={isSubmitting}
              >
                Share
              </button>
            </div>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
};

SupportForm.propTypes = {
  formik: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default SupportForm;
