// Modules
import React, { useState, useEffect } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { useDispatch, useSelector } from "react-redux";
import {
  AmplifyAuthContainer,
  AmplifyAuthenticator,
  AmplifySignIn,
} from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

// Redux
import { setUser } from "./redux/reducers/user.reducer";

// Routes
import routes, { renderRoutes } from "./routes";

// ----------------------------------------------------------------------

const history = createBrowserHistory();

const App = () => {
  // Libraries/utilities
  const dispatch = useDispatch();
  const [authState, setAuthState] = useState();

  // Redux state
  const { user } = useSelector((store) => store.user);

  // Hooks
  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      switch (nextAuthState) {
        case AuthState.SignedIn:
          setAuthState(nextAuthState);
          dispatch(setUser(authData));
          break;

        default:
          break;
      }
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <Router history={history}>{renderRoutes(routes)}</Router>
  ) : (
    <AmplifyAuthContainer>
      <AmplifyAuthenticator usernameAlias="email">
        <AmplifySignIn slot="sign-in" usernameAlias="email" hideSignUp={true} />
      </AmplifyAuthenticator>
    </AmplifyAuthContainer>
  );
};

export default App;
